<!-- eslint-disable prettier/prettier -->
<template>
  <!-- Start Template Root Div -->
  <div
    class="h-full relative"
    :class="{ 'overflow-hidden': showWaiverModal || showPaperWaiverModal }"
  >
    <WaveLoader v-if="fetchingSurfersWaiversLoading" />

    <header class="w-full sticky top-0 z-10">
      <div class="w-full bg-gray-700">
        <div class="container mx-auto py-2 lg:p-8 lg:py-3">
          <div class="w-full flex justify-between items-center p-2">
            <!-- - Logo Half - -->
            <div class="text-white p-5">
              <WaveLogo class="cursor-pointer h-16 md:h-16 lg:h-20" />
            </div>

            <!-- - Refresh Half - -->
            <div class="flex flex-col items-center min-h-16 space-y-2 p-2">
              <!-- - Refresh Info Text - -->
              <p 
                class="
                  w-full text-xs lg:text-sm 
                  text-white text-center font-semibold
                "
              >
                Surf Waivers 🌊 {{ lastFrontEndFetchDate | moment("dddd Do MMMM") }}
                | Last refreshed at {{ lastFrontEndFetchDate | moment("h:mma") }}
              </p>

              <!-- - Refresh Trigger - -->
              <button 
                :class="buttonClasses"
                @click="refreshActiveSurfersWaivers"
              >
                  Refresh SurfWaivers
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full bg-gray-600">
        <div class="container mx-auto py-2 lg:p-8 lg:py-3">
          <div class="w-full flex flex-wrap items-center justify-center p-2">
            <div class="w-4/12 lg:w-2/12 px-1">
              <div
                class="flex justify-start border bg-white rounded-xl overflow-hidden mt-2 lg:mt-0"
              >
                <div class="text-center flex-1">
                  <input
                    type="text"
                    class="w-full px-4 py-2 text-gray-700 placeholder-gray-900 placeholder-gray-900::placeholder font-semibold text-sm"
                    placeholder="Search by Account AK..."
                    v-model="accountAkSearch"
                  />
                </div>
                <div class="flex items-center justify-center text-center">
                  <button
                    class="px-4 border-l text-gray-500 hover:text-gray-900"
                    v-on:click="accountAkSearchAction()"
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-icon="search"
                      role="img"
                      class="h-4 w-4 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9
                          0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7
                          17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7
                          0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128
                          128z"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="w-4/12 lg:w-2/12 px-1">
              <div
                class="flex justify-start border bg-white rounded-xl overflow-hidden mt-2 lg:mt-0"
              >
                <div class="text-center flex-1">
                  <input
                    type="text"
                    class="w-full px-4 py-2 text-gray-700 placeholder-gray-900 placeholder-gray-900::placeholder font-semibold text-sm"
                    placeholder="Search by Surname..."
                    v-model="surnameSearch"
                  />
                </div>
                <div class="flex items-center justify-center text-center">
                  <button
                    class="px-4 border-l text-gray-500 hover:text-gray-900"
                    v-on:click="surnameSearchAction()"
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-icon="search"
                      role="img"
                      class="h-4 w-4 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9
                          0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7
                          17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7
                          0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128
                          128z"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <div class="w-4/12 lg:w-2/12 px-1">
              <div
                class="flex justify-start border bg-white rounded-xl overflow-hidden mt-2 lg:mt-0"
              >
                <div class="text-center flex-1">
                  <input
                    type="datetime-local"
                    class="w-full px-4 py-2 text-gray-700 placeholder-gray-900 placeholder-gray-900::placeholder font-semibold text-sm"
                    v-model="dateSearch"
                  />
                </div>
                <div class="flex items-center justify-center text-center">
                  <button
                    class="px-4 border-l text-gray-500 hover:text-gray-900"
                    v-on:click="dateSearchAction()"
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-icon="history"
                      role="img"
                      class="h-4 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M504 255.532c.252 136.64-111.182 248.372-247.822 248.468-64.014.045-122.373-24.163-166.394-63.942-5.097-4.606-5.3-12.543-.443-17.4l16.96-16.96c4.529-4.529 11.776-4.659
                          16.555-.395C158.208 436.843 204.848 456 256 456c110.549 0 200-89.468 200-200 0-110.549-89.468-200-200-200-55.52 0-105.708
                          22.574-141.923 59.043l49.091 48.413c7.641 7.535 2.305 20.544-8.426 20.544H26.412c-6.627 0-12-5.373-12-12V45.443c0-10.651
                          12.843-16.023 20.426-8.544l45.097 44.474C124.866 36.067 187.15 8 256 8c136.811 0 247.747 110.781 248 247.532zm-167.058
                          90.173l14.116-19.409c3.898-5.36 2.713-12.865-2.647-16.763L280 259.778V116c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12
                          12v168.222l88.179 64.13c5.36 3.897 12.865 2.712 16.763-2.647z"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <div class="w-4/12 lg:w-2/12 px-1 relative">
              <button 
                class="w-full flex justify-between items-center border bg-white rounded-xl mt-2 lg:mt-0 px-4 py-2 capitalize text-gray-700 font-semibold text-sm"
                @click="showEventAkSearchDropdown = !showEventAkSearchDropdown"
              >
                {{ 
                  surfersWaiverEventGroupingsSelected.length == 0 
                    ? 'Select Event'
                    : surfersWaiverEventGroupingsSelected.length > 1
                      ? 'Multiple Events Selected'
                      : surfersWaiverEventGroupingsSelected[0].eventName
                }}
                <span v-if="showEventAkSearchDropdown" class="w-6 h-6">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                  </svg>
                </span>
                <span v-else class="w-6 h-6">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                  </svg>
                </span>
              </button>
              <div 
                v-if="showEventAkSearchDropdown"
                class="min-w-full max-h-32 overflow-y-scroll flex flex-col space-y-2 absolute top-full left-0 border bg-white rounded mt-2 lg:mt-0 p-2"
              >
                <button 
                  @click="eventAkSearch = []"
                  class="p-1 capitalize text-gray-700 font-semibold text-sm text-left hover:bg-gray-100"
                >
                  Clear all events
                </button>
                <button 
                  v-for="(surfersWaiverEventGrouping) in surfersWaiverEventGroupings" 
                  :key="surfersWaiverEventGrouping.eventAk"
                  @click="
                    eventAkSearch.includes(surfersWaiverEventGrouping.eventAk)
                      ? eventAkSearch = eventAkSearch.filter(eventAk => eventAk != surfersWaiverEventGrouping.eventAk) 
                      : eventAkSearch.push(surfersWaiverEventGrouping.eventAk)
                  "
                  :class="eventAkSearch.includes(surfersWaiverEventGrouping.eventAk) ? 'bg-gray-200' : ''"
                  class="p-1 capitalize text-gray-700 font-semibold text-sm text-left hover:bg-gray-100"
                >
                  {{ surfersWaiverEventGrouping.eventName }}
                </button>
              </div>
            </div>

            <div class="w-2/12 px-1">
              <div class="w-full overflow-hidden flex mt-2 lg:mt-0">
                <button
                  class="w-full"
                  :class="buttonClasses"
                  v-on:click="clearActiveSurfersWaiversSearch()"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <section
      class="min-h-screen w-full max-w-screen-xl mx-auto lg:px-3 my-10"
      v-if="this.activeSurfersWaivers.length < 1"
    >
      <div
        class="w-full py-2 lg:w-5/6 flex flex-wrap items-center justify-center mx-auto"
      >
        <div class="bg-gray-100 py-2 px-4 rounded-full shadow-xl">
          <span class="text-gray-600 text-md font-semibold">
            Your Search Did Not Match Any Surfers
          </span>
        </div>
      </div>
    </section>

    <!-- - TimeGrouping Button List - -->
    <div class="w-full max-w-screen-xl mx-auto px-4 flex space-x-4 py-4">
      <button 
        v-for="(activeSurfersWaiver, index) in surfersWaiverTimeGroupings"
        v-bind:key="`timeGroupingButton-${index}`"
        @click="setAnchor(activeSurfersWaiver.performanceDatetime)"
        :class="
          (
            selectedTimeGrouping == activeSurfersWaiver.performanceDatetime ||
            (selectedTimeGrouping == '' && index == 0)
          )
            ? buttonClasses + ' opacity-50'
            : buttonClasses
        "
      >
        {{ activeSurfersWaiver.performanceDatetime | moment("h:mma") }}
      </button>
    </div>

    <!-- - The max-w and padding on this section mimics a container class - -->
    <section
      class="min-h-screen w-full max-w-screen-xl mx-auto px-4"
      v-if="this.activeSurfersWaivers.length > 0"
    >
      <div
        v-for="(activeSurfersWaiver, index) in surfersWaiverTimeGroupings"
        v-bind:key="`timeGroupingList-${index}`"
        v-bind:id="'timeGroupingList-' + activeSurfersWaiver.performanceDatetime"
        :class="
          (
            selectedTimeGrouping == activeSurfersWaiver.performanceDatetime ||
            (selectedTimeGrouping == '' && index == 0)
          )
            ? ''
            : 'hidden'
        "
      >
        <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
          <div
            class="inline-block min-w-full lg:rounded-xl border border-gray-600 overflow-hidden"
          >
            <table class="min-w-full leading-normal">
              <thead>
                <tr>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-600 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider"
                  >
                    Surfer
                  </th>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-600 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider"
                  >
                    Site Location Check-Ins
                  </th>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-600 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider"
                  >
                    Surfer No Show?
                  </th>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-600 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider"
                  >
                    Surfer Seen Video?
                  </th>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-600 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider"
                  >
                    Surf Waiver Status
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(surfer, index) in activeSurfersWaiver.waivers.filter(
                    surfer =>
                      surfer.surfName != 'Spectator Admission' &&
                      surfer.surfName != 'Under 6 Spectator Admission' &&
                      (!eventAkSearch.length || eventAkSearch.includes(surfer.eventAk))
                  )"
                  v-bind:key="index"
                  v-bind:id="surfer.ticketId"
                >
                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white w-5/12 lg:w-3/12"
                  >
                    <div class="flex">
                      <div>
                        <p
                          class="text-gray-900 text-xs lg:text-sm whitespace-no-wrap"
                        >
                          <span class="uppercase font-semibold tracking-wider">
                            {{ surfer.lastName }}
                          </span>
                          <span class="capitalize">
                            {{ parseSurferFirstName(surfer.firstName) }}
                          </span>
                          <span v-if="surfer.metadata?.name" class="font-semibold">
                            - ( {{ surfer.metadata.name}} )
                          </span>
                        </p>
                        <p
                          class="text-gray-600 text-xs lg:text-sm tracking-wider whitespace-no-wrap"
                        >
                          {{ surfer.accountAk }}
                        </p>
                        <p class="text-gray-900 text-xs whitespace-no-wrap">
                          <span class="uppercase font-semibold tracking-wider">
                            {{ surfer.surfName }}
                          </span>
                        </p>
                        <p
                          v-if="
                            !new RegExp(/child/i).test(surfer.surfName) &&
                              convertSurferDOBToAge(surfer.dateOfBirth) < 18
                          "
                          class="text-gray-900 text-xs whitespace-no-wrap"
                        >
                          <span
                            class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
                          >
                            Under 18
                          </span>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white w-4/12 lg:w-auto"
                  >
                    <div
                      v-for="(location, index) in activeSurfSiteLocations"
                      v-bind:key="index"
                      class="inline-flex mb-1 lg:mb-0 mr-1"
                    >
                      <SurferCheckInButton
                        v-bind:surfer="surfer"
                        v-bind:location="location"
                        v-bind:activeDate="activeDate"
                      />
                    </div>
                  </td>
                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white w-1/12"
                  >
                    <SurferNoShowButton
                      v-bind:surfer="surfer"
                      v-bind:activeDate="activeDate"
                    />
                  </td>
                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white w-3/12 lg:w-2/12"
                  >
                    <div class="text-xs lg:text-sm text-center">
                      <span
                        v-if="surfer.hasSeenVideo"
                        class="relative inline-block p-2 font-semibold bg-green-100 uppercase text-xs leading-4 tracking-widest rounded-full"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          class="w-5 h-5 text-green-900"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </span>
                      <span
                        v-else
                        class="relative inline-block p-2 font-semibold bg-red-100 uppercase text-xs leading-4 tracking-widest rounded-full"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          class="w-5 h-5 text-red-900"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </span>
                    </div>
                  </td>
                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white w-3/12 lg:w-2/12"
                  >
                    <div class="flex justify-between">
                      <div class="text-xs lg:text-sm text-center">
                        <span
                          v-if="surfer.paperWaiverSigned"
                          class="relative inline-block p-2 font-semibold text-green-700 border-green-200 uppercase text-xs leading-4 tracking-widest"
                        >
                          <span
                            aria-hidden
                            class="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                          ></span>
                          <span class="relative">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              class="h-4 w-4 block lg:hidden fill-current"
                            >
                              <path
                                d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998
                                  36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997
                                  26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                              ></path>
                            </svg>
                            <span class="relative px-2 hidden lg:block">
                              Paper Signed
                            </span>
                          </span>
                        </span>
                        <span
                          v-else-if="surfer.hasSignedWaiver"
                          class="relative inline-block p-2 font-semibold text-green-700 border-green-200 uppercase text-xs leading-4 tracking-widest"
                        >
                          <span
                            aria-hidden
                            class="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                          ></span>
                          <span class="relative">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              class="h-4 w-4 block lg:hidden fill-current"
                            >
                              <path
                                d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998
                                  36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997
                                  26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                              ></path>
                            </svg>
                            <span class="relative px-2 hidden lg:block">
                              Signed
                            </span>
                          </span>
                        </span>
                        <span
                          v-if="!surfer.hasSignedWaiver"
                          class="relative inline-block p-2 font-semibold text-red-900 uppercase text-xs leading-4 tracking-widest"
                        >
                          <span
                            aria-hidden
                            class="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                          >
                          </span>
                          <span class="relative">
                            <svg
                              viewBox="0 0 352 512"
                              class="h-4 w-4 block lg:hidden fill-current"
                            >
                              <path
                                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48
                                  0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0
                                  44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28
                                  44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19
                                  0-44.48L242.72 256z"
                              ></path>
                            </svg>
                            <span class="relative px-2 hidden lg:block">
                              Not Signed
                            </span>
                          </span>
                        </span>

                        <!-- - Metadata Button - -->
                        <button
                          v-if="surfer.metadata && false"
                          @click="activeModal = `surfer[${surfer.uuid}]-metadata`"
                          class="flex justify-between items-center py-2 my-2 space-x-2"
                        >
                          <h5 class="text-xs">More information</h5>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                          </svg>
                        </button>

                        <!-- - Metadata Modal - -->
                        <div 
                            v-if="activeModal == `surfer[${surfer.uuid}]-metadata`"
                            class="fixed z-10 top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-10"
                          >
                            <div class="bg-white rounded-xl p-4 text-left">
                              <!-- - Modal Header - -->
                              <div class="flex justify-between items-center space-x-2">
                                <h6 class="text-xl">Waiver Metadata:</h6>
                                <button @click="activeModal = ''">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                  </svg>
                                </button>
                              </div>
                              <!-- - Modal Content - -->
                              <span v-for="(metadata, key) in surfer.metadata" :key="`surfer[${surfer.uuid}]-metadata[${key}]`">
                                {{ `${key}: ${metadata}` }}
                              </span>
                            </div>
                          </div>
                      </div>

                      <!-- Waiver Button -->
                      <div class="text-gray-700 text-center">
                        <button
                          type="button"
                          class="inline-block"
                          v-bind:class="{
                            'text-gray-500 hover:text-gray-700': !surfer.hasSignedWaiver,
                            'text-white': surfer.hasSignedWaiver
                          }"
                          v-on:click="openWaiverModal(surfer)"
                        >
                          <svg
                            class="inline-block h-6 w-6 fill-current"
                            viewBox="0 0 640 512"
                          >
                            <path
                              d="M623.2 192c-51.8 3.5-125.7 54.7-163.1 71.5-29.1 13.1-54.2 24.4-76.1 24.4-22.6 0-26-16.2-21.3-51.9
                                1.1-8 11.7-79.2-42.7-76.1-25.1 1.5-64.3 24.8-169.5 126L192 182.2c30.4-75.9-53.2-151.5-129.7-102.8L7.4
                                116.3C0 121-2.2 130.9 2.5 138.4l17.2 27c4.7 7.5 14.6 9.7 22.1 4.9l58-38.9c18.4-11.7 40.7 7.2 32.7
                                27.1L34.3 404.1C27.5 421 37 448 64 448c8.3 0 16.5-3.2 22.6-9.4 42.2-42.2 154.7-150.7 211.2-195.8-2.2
                                28.5-2.1 58.9 20.6 83.8 15.3 16.8 37.3 25.3 65.5 25.3 35.6 0 68-14.6 102.3-30 33-14.8 99-62.6
                                138.4-65.8 8.5-.7 15.2-7.3 15.2-15.8v-32.1c.2-9.1-7.5-16.8-16.6-16.2z"
                            ></path>
                          </svg>
                        </button>
                      </div>

                      <!-- Paper Waiver Button -->
                      <div class="text-gray-700 text-center">
                        <button
                          type="button"
                          class="inline-block"
                          v-bind:class="{
                            'text-gray-500 hover:text-gray-700': !surfer.hasSignedWaiver,
                            'text-white': surfer.hasSignedWaiver
                          }"
                          v-on:click="openPaperWaiverModal(surfer)"
                        >
                          <svg
                            class="inline-block h-6 w-6"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 
                                00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto"
          v-if="
            activeSurfersWaiver.waivers.filter(
              surfer =>
                surfer.surfName == 'Spectator Admission' ||
                surfer.surfName == 'Under 6 Spectator Admission'
            ).length > 0
          "
        >
          <div
            class="inline-block min-w-full lg:rounded-xl border border-gray-600 overflow-hidden"
          >
            <table class="min-w-full leading-normal">
              <thead>
                <tr>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-600 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider"
                  >
                    Spectator
                  </th>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-600 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider"
                  >
                    Site Location Check-Ins
                  </th>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-600 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider"
                  >
                    Spectator No Show?
                  </th>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-600 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider"
                  >
                    Surfer Seen Video?
                  </th>
                  <th
                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-600 text-left text-xs font-semibold text-gray-100 uppercase tracking-wider"
                  >
                    Surf Waiver Status
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(surfer, index) in activeSurfersWaiver.waivers.filter(
                    surfer =>
                      surfer.surfName == 'Spectator Admission' ||
                      surfer.surfName == 'Under 6 Spectator Admission'
                  )"
                  v-bind:key="index"
                  v-bind:id="surfer.ticketId"
                >
                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white w-5/12 lg:w-3/12"
                  >
                    <div class="flex">
                      <div>
                        <p
                          class="text-gray-900 text-xs lg:text-sm whitespace-no-wrap"
                        >
                          <span class="uppercase font-semibold tracking-wider">
                            {{ surfer.lastName }}
                          </span>
                          <span class="capitalize">
                            {{ parseSurferFirstName(surfer.firstName) }}
                          </span>
                        </p>
                        <p
                          class="text-gray-600 text-xs lg:text-sm tracking-wider whitespace-no-wrap"
                        >
                          {{ surfer.accountAk }}
                        </p>
                        <p class="text-gray-900 text-xs whitespace-no-wrap">
                          <span class="uppercase font-semibold tracking-wider">
                            {{ surfer.surfName }}
                          </span>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white w-4/12 lg:w-auto"
                  >
                    <div
                      v-for="(location, index) in activeSurfSiteLocations"
                      v-bind:key="index"
                      class="inline-flex mb-1 lg:mb-0 mr-1"
                    >
                      <SurferCheckInButton
                        v-bind:surfer="surfer"
                        v-bind:location="location"
                        v-bind:activeDate="activeDate"
                      />
                    </div>
                  </td>
                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white w-1/12"
                  >
                    <SurferNoShowButton
                      v-bind:surfer="surfer"
                      v-bind:activeDate="activeDate"
                    />
                  </td>
                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white w-3/12 lg:w-2/12"
                  >
                    <div class="flex justify-between">
                      <div class="text-gray-700 text-xs lg:text-sm text-center">
                        <span
                          class="relative inline-block p-2 font-semibold text-gray-700 border-gray-200 uppercase text-xs leading-4 tracking-widest"
                        >
                          <span
                            aria-hidden
                            class="absolute inset-0 bg-gray-200 opacity-50 rounded-full"
                          ></span>
                          <span class="relative">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              class="h-4 w-4 block lg:hidden fill-current"
                            >
                              <path
                                d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998
                                  36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997
                                  26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                              ></path>
                            </svg>
                            <span class="relative px-2 hidden lg:block">
                              Not Applicable
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white w-3/12 lg:w-2/12"
                  >
                    <div class="flex justify-between">
                      <div class="text-gray-700 text-xs lg:text-sm text-center">
                        <span
                          class="relative inline-block p-2 font-semibold text-gray-700 border-gray-200 uppercase text-xs leading-4 tracking-widest"
                        >
                          <span
                            aria-hidden
                            class="absolute inset-0 bg-gray-200 opacity-50 rounded-full"
                          ></span>
                          <span class="relative">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              class="h-4 w-4 block lg:hidden fill-current"
                            >
                              <path
                                d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998
                                  36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997
                                  26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                              ></path>
                            </svg>
                            <span class="relative px-2 hidden lg:block">
                              Not Applicable
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>

    <!-- Waiver Modal -->
    <WavierModal
      :show="showWaiverModal"
      :close="closeWaiverModal"
      :surfer="selectedSurfer"
      :acceptWaiver="signSurferWaiver"
    />

    <!-- Paper Waiver Modal -->
    <PaperWavierModal
      :show="showPaperWaiverModal"
      :close="closePaperWaiverModal"
      :acceptPaperWaiver="signSurferPaperWaiver"
    />
  </div>
  <!-- End Template Root Div -->
</template>

<script>
	/* eslint max-len: ["error", { "ignoreUrls": true }] */
	/* eslint max-len: ["error", { "ignoreComments": true }] */
	/* eslint max-len: ["error", { "ignoreStrings": true }] */
	/* eslint max-len: ["error", { "ignoreTemplateLiterals": true }] */
	/* eslint max-len: ["error", { "code": 300 }] */
	/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
	/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["bar"] }] */

	/*eslint no-param-reassign: ["error", { "props": false }]*/

	import { mapState, mapActions } from "vuex";

	import SurferCheckInButton from "@/components/SurferCheckInButton.vue";
	import SurferNoShowButton from "@/components/SurferNoShowButton.vue";
	import WavierModal from "@/components/WaiverModal.vue";
	import PaperWavierModal from "@/components/PaperWaiverModal.vue";

	// @ is an alias to /src
	import WaveLogo from "@/components/WaveLogo.vue";
	// import WaveAlert from "@/components/WaveAlert.vue";
	import WaveLoader from "@/components/WaveLoader.vue";

	// import BaseDateSelector from "@/components/BaseDateSelector.vue"

	export default {
		components: {
			// Initialise components here:
			WaveLogo,
			WaveLoader,
			SurferCheckInButton,
			SurferNoShowButton,
			WavierModal,
			PaperWavierModal
		},
		// Data is an arrow function returning an object:
		data: () => ({
			buttonClasses:
				"bg-pear-500 hover:bg-pear-700 text-sherpa-500 text-center text-sm font-bold py-2 px-3 rounded-full transition duration-100",
			activeDate: Date.parse(new Date()),
      activeModal: "",
			lastFrontEndFetchDate: Date.parse(new Date()),
			buttonClicked: false,
			filteredSurfersWaivers: [],
			hasUpdatedOnLoad: 0,
			fetchedTimestamp: "",
			surnameSearch: "",
			accountAkSearch: "",
			dateSearch: new Date().toISOString().substring(0, 10),
      eventAkSearch: [],
      showEventAkSearchDropdown: false,
			fetchActiveSurfersWaiversPoll: null,
			showDatePickerDialgoue: false,
			historyModeEnabled: false,
			showWaiverModal: false,
			showPaperWaiverModal: false,
			selectedSurfer: {},
			selectedTimeGrouping: "",
			anchor: ""
		}),
		// Computed Properties:
		computed: {
			// Mapped State From Vuex Namespaced Module "surfers" Store:
			...mapState("surfers", [
				"activeSurfersWaivers",
				"fetchingSurfersWaiversLoading",
				"fetchingSurfersWaiversSuccess",
				"fetchingSurfersWaiversTimestamp"
			]),
			// Mapped State From Vuex Namespaced Module "locations" Store:
			...mapState("locations", [
				"activeSurfSiteId",
				"activeSurfSiteLocations"
			]),
			surfersWaiverTimeGroupings() {
				let surfersWaivers = [];

				this.activeSurfersWaivers.forEach(surfersWaiver => {
					if (
						surfersWaivers.findIndex(
							x =>
								x.performanceDatetime ===
								surfersWaiver.performanceDatetime
						) === -1
					) {
						surfersWaivers.push({
							performanceDatetime:
								surfersWaiver.performanceDatetime,
							waivers: []
						});
					}
				});

				this.activeSurfersWaivers.forEach(surfersWaiver => {
					surfersWaivers
						.find(
							x =>
								x.performanceDatetime ===
								surfersWaiver.performanceDatetime
						)
						.waivers.push(surfersWaiver);
				});

				return surfersWaivers;
			},
      surfersWaiverEventGroupings() {
        let surfersWaiverEventGroupings = [];

        // Find current TimeGrouping
        if(!this.surfersWaiverTimeGroupings.length)
          return [];
        const surfersWaiverTimeGrouping = this.surfersWaiverTimeGroupings.find(
          surfersWaiverTimeGrouping => surfersWaiverTimeGrouping.performanceDatetime === this.selectedTimeGrouping
        ) ?? this.surfersWaiverTimeGroupings[0];

        // Extract groupings
        surfersWaiverTimeGrouping.waivers.forEach(surfersWaiver => {
          if(
            surfersWaiverEventGroupings.findIndex(
              x => x.eventAk === surfersWaiver.eventAk
            ) === -1
          ) {
            surfersWaiverEventGroupings.push({
              eventAk: surfersWaiver.eventAk,
              eventName: surfersWaiver?.event?.eventName ?? `Unnamed - ${surfersWaiver.eventAk}`
            });
          }
        });

        return surfersWaiverEventGroupings;
      },
      surfersWaiverEventGroupingsSelected() {
        // eslint-disable-next-line no-unused-vars
        return this.surfersWaiverEventGroupings.filter(
          surfersWavierEventGrouping => this.eventAkSearch.includes(surfersWavierEventGrouping.eventAk)
        );
      }
		},
		// Lifecycle Hooks:
		created() {
			// Check The URL For Params:
			if (this.getUrlParam("date", null) != null) {
				var paramDate = this.getUrlParam("date", null);
				this.activeDate = Date.parse(paramDate);
			}

      // Set anchor
      this.anchor = window.location.hash.replace("#", "");

			// Dispatch Action ['surfSites/fetchSurfSiteLocations'] to Vuex:
			this.fetchSurfSiteLocationsAction();

			// Dispatch Action ['surfers/fetchSurfersWaivers'] to Vuex:
			this.fetchActiveSurfersWaiversAction();

			// Dispatch Periodic Action ['surfers/fetchSurfersWaivers'] to Vuex:
			this.fetchActiveSurfersWaiversInterval();
		},
		beforeDestroy() {
			// Clear The Interval Related To The Dispatch Periodic Action ['surfers/fetchSurfersWaivers'] Long Polling:
			clearInterval(this.fetchActiveSurfersWaiversPoll);
		},
		// Watchers:
		watch: {
			// We're watching the anchor and then changing the selectedTimeGrouping to match
			anchor(newAnchor) {
				this.selectedTimeGrouping = newAnchor.replace("#", "");
			},
			surnameSearch() {
				// If User Clears Their Search In The Input Field - Refresh Active Surfers Waivers List:
				if (this.surnameSearch.length == 0) {
					this.refreshActiveSurfersWaivers();
				}
			},
			accountAkSearch() {
				// If User Clears Their Search In The Input Field - Refresh Active Surfers Waivers List:
				if (this.accountAkSearch.length == 0) {
					this.refreshActiveSurfersWaivers();
				}
			}
		},
		// Component Methods:
		methods: {
			// Standard Vanilla JS Functions:
			setAnchor(anchor) {
				// Set anchor data attribute (triggers watchers)
				this.anchor = anchor;

				// Set anchor tag in the window (persistent on refresh)
				window.location.hash = anchor;
			},
			scrollToID(targetID) {
				document.getElementById(targetID).scrollIntoView();
			},
			getUrlVars() {
				var vars = {};
				window.location.href.replace(
					/[?&]+([^=&]+)=([^&]*)/gi,
					function(m, key, value) {
						vars[key] = value;
					}
				);
				return vars;
			},
			getUrlParam(parameter, defaultValue) {
				var urlParamater = defaultValue;
				if (window.location.href.indexOf(parameter) > -1) {
					urlParamater = this.getUrlVars()[parameter];
				}
				return urlParamater;
			},
			// Mapped Store Actions
			...mapActions([
				"surfers/setSurfersWaivers",
				"surfers/fetchSurfersWaivers",
				"surfers/updateSurfersWaiver"
			]),
			...mapActions(["locations/fetchSurfSiteLocations"]),
			// Wave Waivers List Specific Functionality:
			fetchSurfSiteLocationsAction() {
				this["locations/fetchSurfSiteLocations"]();
			},
			fetchActiveSurfersWaiversAction() {
				this["surfers/fetchSurfersWaivers"]({
					date: new Date(this.activeDate).toISOString()
				});
			},
			fetchActiveSurfersWaiversFromLocalStorage() {
				this["surfers/fetchSurfersWaiversFromLocalStorage"]();
			},
			// Wave Waiver Application URL Creation:
			openWaiverModal(surfer) {
				// let url = `https://waiver.thewave.com/terms?accountId=${surfer.accountAk}&scan=true`;
				// let waiverSignURL = window.open(url, "_blank");
				// waiverSignURL.focus();
				this.selectedSurfer = surfer;
				this.showWaiverModal = true;
			},
			// Wave Waiver Application URL Creation:
			openPaperWaiverModal(surfer) {
				// let url = `https://waiver.thewave.com/terms?accountId=${surfer.accountAk}&scan=true`;
				// let waiverSignURL = window.open(url, "_blank");
				// waiverSignURL.focus();
				this.selectedSurfer = surfer;
				this.showPaperWaiverModal = true;
			},
			closeWaiverModal() {
				this.selectedSurfer = {};
				this.showWaiverModal = false;
			},
			closePaperWaiverModal() {
				this.selectedSurfer = {};
				this.showPaperWaiverModal = false;
			},
			async signSurferWaiver() {
				this.selectedSurfer.hasSignedWaiver = true;

				const res = await this.$restfulAPIService.updateResource(
					"waivers",
					this.selectedSurfer.uuid,
					this.selectedSurfer
				);

				if (res.status === 200 || res.statusText === "OK") {
					this.closeWaiverModal();
				}
			},
			async signSurferPaperWaiver() {
				this.selectedSurfer.hasSignedWaiver = true;
				this.selectedSurfer.paperWaiverSigned = true;

				const res = await this.$restfulAPIService.updateResource(
					"waivers",
					this.selectedSurfer.uuid,
					this.selectedSurfer
				);

				if (res.status === 200 || res.statusText === "OK") {
					this.closePaperWaiverModal();
				}
			},
			fetchActiveSurfersWaiversInterval() {
				this.fetchActiveSurfersWaiversPoll = setInterval(() => {
					if (!this.searchStarted) {
						this.activeDate = Date.parse(new Date());
						this.lastFrontEndFetchDate = Date.parse(new Date());
						this.fetchActiveSurfersWaiversAction();
					}
				}, 10 * 60 * 1000);
			},
			refreshActiveSurfersWaivers() {
				this.fetchActiveSurfersWaiversAction();
				this.filteredSurfersWaivers = this.activeSurfersWaivers;
			},
			clearActiveSurfersWaiversSearch() {
				this.refreshActiveSurfersWaivers();
				this.surnameSearch = "";
				this.accountAkSearch = "";
			},
			parseSurferFirstName(surferFirstName) {
				if (surferFirstName.length > 0) {
					return `, ${surferFirstName}`;
				} else {
					return "No Name";
				}
			},
			// Search Actions
			surnameSearchAction() {
				let surfersWaivers = this.activeSurfersWaivers;

				// We only want to dispatch a search if the search string has some length to it:
				if (surfersWaivers && this.surnameSearch.length > 1) {
					let surname = this.surnameSearch.toLowerCase();

					const matchingSurfersWaivers = surfersWaivers.filter(
						entry => entry.lastName.toLowerCase() === surname
					);
					this["surfers/setSurfersWaivers"](matchingSurfersWaivers);
				}
			},
			accountAkSearchAction() {
				let surfersWaivers = this.activeSurfersWaivers;

				// We only want to dispatch a search if the search string has some length to it:
				if (surfersWaivers && this.accountAkSearch.length > 1) {
					let accountAk = this.accountAkSearch;

					const matchingSurfersWaivers = surfersWaivers.filter(
						entry => entry.accountAk === accountAk
					);

					// Persist the search term:
					this["surfers/setSurfersWaivers"](matchingSurfersWaivers);
				}
			},
			convertSurferDOBToAge(dateOfBirth) {
				return this.$moment().diff(
					this.$moment(dateOfBirth, "DD/MM/YYYY"),
					"years",
					false
				);
			},
			dateSearchAction() {
				if (
					this.dateSearch.length > 0 &&
					!isNaN(Date.parse(this.dateSearch))
				) {
					this.activeDate = Date.parse(this.dateSearch);
					this.fetchActiveSurfersWaiversAction();
				}
			}
		}
	};
</script>
